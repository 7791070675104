<template>
  <div class="quickSupportCommandEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('menu.quickSupportCommand') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>Name</label>
        <input
          v-model="quickSupportCommand.name"
          v-focus
          class="form-control"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('applicationType') }}</label>
        <textarea
          id="applicationType"
          v-model="quickSupportCommand.applicationType"
          placeholder="Type in one or more Application types, seperated with semicolon. Leave empty if it's for all types"
          rows="3"
          class="form-control"
        />
        <hr class="m-0 mb-3">
        <label>Ignored Application Types</label>
        <textarea
          id="ignoredApplicationTypes"
          v-model="quickSupportCommand.ignoredApplicationType"
          placeholder="Type in one or more Application types, seperated with semicolon. On these Application types the command will not be shown."
          rows="3"
          class="form-control"
        />
        <hr class="m-0 mb-3">
        <label>Remote Command</label>
        <input
          v-model="quickSupportCommand.remoteCommand"
          class="form-control"
          type="text"
        >
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!quickSupportCommand.name || !quickSupportCommand.remoteCommand"
        @click.prevent="editQuickSupportCommand()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('edit') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
export default {
  name: "QuickSupportCommandEdit",
  props: {
    quickSupportCommand: {
      type: Object,
      required: true
    }
  },
  methods: {
    async editQuickSupportCommand () {
      await this.axios.put('/CentralDeviceManagement/QuickSupportCommand', this.quickSupportCommand)
        .then(() => {
          this.$snotify.success(this.$t('quickSupportCommand.updatedSuccessfully'));
          this.$emit("reload");
        })
    }
  }
}
</script>

<style scoped>
.quickSupportCommandEdit .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>